html,
body {
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
#root {
  position: relative;
  background-color: #f5f6f7;
}

#root,
#root > div:first-child {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
}
