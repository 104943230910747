#actevee-datepicker {
  position: relative;
}

#actevee-datepicker [data-baseweb='base-input'] {
  background-color: #fff;
  border-color: #f1f1f5;
  border-width: 1px;
  border-radius: 7px;
}

#actevee-datepicker span[data-type='datepicker-placeholder'] {
  font-size: 11px;
  color: #92929d;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 0;
  left: 0.5rem;
  overflow: hidden;
  padding: 0 0.5rem;
  transform: translateY(-50%);
  pointer-events: none;
}

div[aria-label^='Not available'] {
  color: #c9c9d1;
}
